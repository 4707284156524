import { styled } from 'styled-components';
import { Colors } from '../../../../../play-core/styles/Colors';
import { Fonts } from '../../../../../play-core/styles/Fonts';
import { Viewports } from '../../../../../play-core/styles/Viewports';
import { PlayContainer, ContentContainer, defaultContainerPadding, getPlayContainerSidePaddingStyle } from '../../../components/PlayContainer/PlayContainer';
import { getTopicBackground } from '../../Topic/TopicPage.styles';
export const Container = styled(PlayContainer).withConfig({
  displayName: "ShowInfostyles__Container",
  componentId: "sc-10rfd8n-0"
})(["", "{display:flex;flex-direction:row;gap:24px;}", "{", "{flex-direction:column-reverse;padding:0;}}", "{margin-top:24px;}&:before{z-index:-1;width:100%;height:572px;position:absolute;content:'';background:", ";", "{margin-top:-24px;}}"], ContentContainer, Viewports.for('mediumDown'), ContentContainer, Viewports.for('largeUp'), ({
  topicUrn = ''
}) => getTopicBackground(topicUrn, 0.2), Viewports.for('largeUp'));
export const Description = styled.p.withConfig({
  displayName: "ShowInfostyles__Description",
  componentId: "sc-10rfd8n-1"
})(["", ";line-height:26px;color:", ";max-width:900px;margin:0;"], Fonts.light(18), Colors.WHITE);
export const InfoContainer = styled.div.withConfig({
  displayName: "ShowInfostyles__InfoContainer",
  componentId: "sc-10rfd8n-2"
})(["display:flex;flex-direction:column;align-items:flex-start;gap:16px;flex:2;", "{", "}"], Viewports.for('mediumDown'), getPlayContainerSidePaddingStyle(defaultContainerPadding));
export const ImageContainer = styled.div.withConfig({
  displayName: "ShowInfostyles__ImageContainer",
  componentId: "sc-10rfd8n-3"
})(["flex:1;"]);